/**
 * ATTENTION: This file is auto generated by using "prepareTemplates".
 * Do not change the content!
 *
 */

/**
 * Library Index blockquote to autogenerate all the elements and extensions
 * Used by "prepareBlockquotes"
 */

import Blockquote from './blockquote/Blockquote'
export * from './blockquote/Blockquote'
export default Blockquote
