/** This file is auto generated by convertSvgToJsx.ts */

import React from 'react'
const home = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.667 12.976v-3.66c0-.367.298-.664.666-.664h3.334c.368 0 .666.297.666.665v3.659m-8 1.996h11.334c.736 0 1.333-.596 1.333-1.33V6.674c0-.43-.208-.833-.558-1.083L8.775 1.553a1.336 1.336 0 0 0-1.55 0l-5.667 4.04A1.33 1.33 0 0 0 1 6.674v6.966c0 .735.597 1.33 1.333 1.33Z"
    />
  </svg>
)
export default home
