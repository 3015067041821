/**
 * Web ToggleButtonGroup Context
 *
 */

import React from 'react'

const ToggleButtonGroupContext = React.createContext({})

export default ToggleButtonGroupContext
