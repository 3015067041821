/**
 * Sidebar Icon
 *
 */

import React from 'react'

export default function colors(props = {}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.8581 8.86104C22.7491 7.97004 23.2496 6.7616 23.2496 5.50154C23.2496 4.24148 22.7491 3.03303 21.8581 2.14204C20.9671 1.25104 19.7586 0.750488 18.4986 0.750488C17.2385 0.750488 16.0301 1.25104 15.1391 2.14204L5.06008 12.221C4.18795 13.0934 3.62201 14.2249 3.44708 15.446L3.04708 18.268L1.02808 20.284C0.850016 20.4623 0.75 20.704 0.75 20.956C0.75 21.208 0.850016 21.4497 1.02808 21.628L2.37208 22.972C2.55039 23.1501 2.79209 23.2501 3.04408 23.2501C3.29608 23.2501 3.53777 23.1501 3.71608 22.972L5.73208 20.956L8.55408 20.556C9.77518 20.3811 10.9067 19.8152 11.7791 18.943L21.8581 8.86104Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.76294 2.14197L21.8579 14.237"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
