/**
 * Sidebar Icon
 *
 */

import React from 'react'

export default function contact(props = {}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.24902 18.75C6.90588 18.75 8.24902 17.4069 8.24902 15.75C8.24902 14.0931 6.90588 12.75 5.24902 12.75C3.59217 12.75 2.24902 14.0931 2.24902 15.75C2.24902 17.4069 3.59217 18.75 5.24902 18.75Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.47293 23.25C9.1672 22.3733 8.59628 21.6134 7.83934 21.0757C7.08239 20.538 6.17691 20.2491 5.24843 20.2491C4.31994 20.2491 3.41446 20.538 2.65751 21.0757C1.90057 21.6134 1.32965 22.3733 1.02393 23.25"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.516 14.589C21.8013 15.2674 21.8273 16.0269 21.5891 16.7232C21.3508 17.4195 20.8649 18.004 20.2239 18.3654C19.5828 18.7268 18.8312 18.8399 18.1122 18.6833C17.3931 18.5267 16.7567 18.1113 16.324 17.516"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9729 23.25C22.6672 22.3733 22.0963 21.6134 21.3393 21.0757C20.5824 20.538 19.6769 20.2491 18.7484 20.2491C17.8199 20.2491 16.9145 20.538 16.1575 21.0757C15.4006 21.6134 14.8297 22.3733 14.5239 23.25"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 0.75H21.75C22.1478 0.75 22.5294 0.908035 22.8107 1.18934C23.092 1.47064 23.25 1.85218 23.25 2.25V10.5C23.25 10.8978 23.092 11.2794 22.8107 11.5607C22.5294 11.842 22.1478 12 21.75 12H17.25L12.75 16.5V12H9.75C9.35218 12 8.97064 11.842 8.68934 11.5607C8.40804 11.2794 8.25 10.8978 8.25 10.5V2.25C8.25 1.85218 8.40804 1.47064 8.68934 1.18934C8.97064 0.908035 9.35218 0.75 9.75 0.75H11.25"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.874 3.375C13.874 3.00416 13.984 2.64165 14.19 2.33331C14.396 2.02496 14.6889 1.78464 15.0315 1.64273C15.3741 1.50081 15.7511 1.46368 16.1148 1.53603C16.4785 1.60838 16.8126 1.78695 17.0749 2.04918C17.3371 2.3114 17.5157 2.64549 17.588 3.00921C17.6603 3.37292 17.6232 3.74992 17.4813 4.09253C17.3394 4.43514 17.0991 4.72798 16.7907 4.93401C16.4824 5.14003 16.1199 5.25 15.749 5.25"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.749 8.25C15.8232 8.25 15.8957 8.27199 15.9574 8.3132C16.019 8.3544 16.0671 8.41297 16.0955 8.48149C16.1239 8.55002 16.1313 8.62542 16.1168 8.69816C16.1024 8.7709 16.0666 8.83772 16.0142 8.89017C15.9617 8.94261 15.8949 8.97832 15.8222 8.99279C15.7494 9.00726 15.674 8.99984 15.6055 8.97145C15.537 8.94307 15.4784 8.89501 15.4372 8.83334C15.396 8.77167 15.374 8.69917 15.374 8.625C15.374 8.52554 15.4135 8.43016 15.4839 8.35983C15.5542 8.28951 15.6496 8.25 15.749 8.25"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
