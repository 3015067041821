/**
 * ATTENTION: This file is auto generated by using "prepareTemplates".
 * Do not change the content!
 *
 */

/**
 * Library Index flex to autogenerate all the components and extensions
 * Used by "prepareFlexs"
 */

import Flex from './flex/Flex'
export * from './flex/Flex'
export default Flex
