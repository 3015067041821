/**
 * ATTENTION: This file is auto generated by using "prepareTemplates".
 * Do not change the content!
 *
 */

/**
 * Library Index form-status to autogenerate all the components and extensions
 * Used by "prepareFormStatuss"
 */

import FormStatus from './form-status/FormStatus'
export * from './form-status/FormStatus'
export default FormStatus
