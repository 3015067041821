/**
 * Sidebar Icon
 *
 */

import React from 'react'

export default function extensions(props = {}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.522 10.411V4.87501C16.5219 4.73568 16.483 4.59913 16.4097 4.48063C16.3364 4.36213 16.2316 4.26635 16.107 4.20401L12.357 2.32901C12.2529 2.27723 12.1382 2.25027 12.022 2.25027C11.9057 2.25027 11.7911 2.27723 11.687 2.32901L7.93697 4.20001C7.81222 4.26217 7.70728 4.3579 7.63396 4.47644C7.56065 4.59499 7.52186 4.73163 7.52197 4.87101V10.407L12.022 12.657L16.522 10.411Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.022 18.661V12.661L7.52197 10.411L3.43697 12.454C3.31222 12.5162 3.20728 12.6119 3.13396 12.7304C3.06065 12.849 3.02186 12.9856 3.02197 13.125V18.2C3.02186 18.3394 3.06065 18.476 3.13396 18.5946C3.20728 18.7131 3.31222 18.8089 3.43697 18.871L7.18697 20.746C7.29105 20.7978 7.40572 20.8247 7.52197 20.8247C7.63822 20.8247 7.75289 20.7978 7.85697 20.746L12.022 18.661Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.607 18.869C20.7316 18.8067 20.8364 18.7109 20.9097 18.5924C20.983 18.4739 21.0219 18.3373 21.022 18.198V13.125C21.0219 12.9857 20.983 12.8491 20.9097 12.7306C20.8364 12.6121 20.7316 12.5163 20.607 12.454L16.522 10.411L12.022 12.661V18.661L16.187 20.744C16.2911 20.7958 16.4057 20.8228 16.522 20.8228C16.6382 20.8228 16.7529 20.7958 16.857 20.744L20.607 18.869Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
