/**
 * Web RadioGroup Context
 *
 */

import React from 'react'

const RadioGroupContext = React.createContext({})

export default RadioGroupContext
