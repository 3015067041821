/**
 * ATTENTION: This file is auto generated by using "prepareTemplates".
 * Do not change the content!
 *
 */

/**
 * Library Index form-label to autogenerate all the components and extensions
 * Used by "prepareFormLabels"
 */

import FormLabel from './form-label/FormLabel'
export * from './form-label/FormLabel'
export default FormLabel
