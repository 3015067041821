/**
 * Sidebar Icon
 *
 */

import React from 'react'

export default function tools(props = {}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.39988 16.742L16.7409 9.40002C17.5898 9.80515 18.5435 9.9372 19.4706 9.77799C20.3977 9.61877 21.2527 9.17612 21.9178 8.51097C22.583 7.84581 23.0256 6.99084 23.1849 6.06374C23.3441 5.13664 23.212 4.18298 22.8069 3.33402L21.3819 4.76302C21.2411 4.9038 21.074 5.01547 20.89 5.09165C20.7061 5.16784 20.509 5.20706 20.3099 5.20706C19.9078 5.20706 19.5222 5.04733 19.2379 4.76302C18.9536 4.47871 18.7938 4.0931 18.7938 3.69102C18.7938 3.28894 18.9536 2.90333 19.2379 2.61902L20.6619 1.19402C19.8131 0.790266 18.86 0.659304 17.9338 0.819132C17.0075 0.97896 16.1535 1.42173 15.489 2.0866C14.8246 2.75146 14.3824 3.60579 14.2232 4.53216C14.064 5.45852 14.1956 6.41147 14.5999 7.26002L7.25888 14.6C6.40992 14.1949 5.45626 14.0628 4.52916 14.2221C3.60206 14.3813 2.74709 14.8239 2.08194 15.4891C1.41678 16.1542 0.974131 17.0092 0.814918 17.9363C0.655704 18.8634 0.787751 19.8171 1.19288 20.666L2.61788 19.242C2.9022 18.9577 3.28781 18.798 3.68988 18.798C4.09196 18.798 4.47757 18.9577 4.76188 19.242C5.0462 19.5263 5.20592 19.9119 5.20592 20.314C5.20592 20.7161 5.0462 21.1017 4.76188 21.386L3.33788 22.808C4.1867 23.2118 5.13973 23.3427 6.066 23.1829C6.99226 23.0231 7.84629 22.5803 8.51072 21.9154C9.17514 21.2506 9.61735 20.3963 9.77657 19.4699C9.93579 18.5435 9.8042 17.5906 9.39988 16.742V16.742Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
