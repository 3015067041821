/**
 * ATTENTION: This file is auto generated by using "prepareTemplates".
 * Do not change the content!
 *
 */

/**
 * Library Index code to autogenerate all the elements and extensions
 * Used by "prepareCodes"
 */

import Code from './code/Code'
export * from './code/Code'
export default Code
