/**
 * Sidebar Icon
 *
 */

import React from 'react'

export default function accessibility(props = {}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 6.75C13.6569 6.75 15 5.40685 15 3.75C15 2.09315 13.6569 0.75 12 0.75C10.3431 0.75 9 2.09315 9 3.75C9 5.40685 10.3431 6.75 12 6.75Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 14.25V12C15.75 11.0054 15.3549 10.0516 14.6517 9.34835C13.9484 8.64509 12.9946 8.25 12 8.25C11.0054 8.25 10.0516 8.64509 9.34835 9.34835C8.64509 10.0516 8.25 11.0054 8.25 12V14.25H9.75L10.5 20.25H13.5L14.25 14.25H15.75Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 8.25C5.74264 8.25 6.75 7.24264 6.75 6C6.75 4.75736 5.74264 3.75 4.5 3.75C3.25736 3.75 2.25 4.75736 2.25 6C2.25 7.24264 3.25736 8.25 4.5 8.25Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.7 9.94698C5.13585 9.75713 4.53463 9.7042 3.94598 9.79254C3.35733 9.88089 2.79813 10.108 2.31457 10.4551C1.831 10.8022 1.43693 11.2593 1.16488 11.7888C0.892841 12.3182 0.750633 12.9047 0.75 13.5V14.25H2.25L3 18.75H6L6.376 16.494"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 8.25C20.7426 8.25 21.75 7.24264 21.75 6C21.75 4.75736 20.7426 3.75 19.5 3.75C18.2574 3.75 17.25 4.75736 17.25 6C17.25 7.24264 18.2574 8.25 19.5 8.25Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3 9.94698C18.8642 9.75713 19.4654 9.7042 20.054 9.79254C20.6427 9.88089 21.2019 10.108 21.6855 10.4551C22.169 10.8022 22.5631 11.2593 22.8351 11.7888C23.1072 12.3182 23.2494 12.9047 23.25 13.5V14.25H21.75L21 18.75H18L17.624 16.494"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.25 21C23.25 22.243 18.213 23.25 12 23.25C5.787 23.25 0.75 22.243 0.75 21"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
