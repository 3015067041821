/**
 * ATTENTION: This file is auto generated by using "prepareTemplates".
 * Do not change the content!
 *
 */

/**
 * Library Index button to autogenerate all the components and extensions
 * Used by "prepareButtons"
 */

import Button from './button/Button'
export * from './button/Button'
export default Button
