/**
 * ATTENTION: This file is auto generated by using "prepareTemplates".
 * Do not change the content!
 *
 */

/**
 * Library Index hr to autogenerate all the elements and extensions
 * Used by "prepareHrs"
 */

import Hr from './hr/Hr'
export * from './hr/Hr'
export default Hr
